// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "@hotwired/turbo-rails"
import  "controllers"
import '@knt/view-components'
import '@fortawesome/fontawesome-free/css/all'

// add bootstrap
import '../../../node_modules/bootstrap/dist/js/bootstrap'
import '../../../node_modules/bootstrap/dist/css/bootstrap'

import SwaggerUI from 'swagger-ui'
import 'swagger-ui/dist/swagger-ui.css'

import 'chartkick/chart.js'

// for json syntax highlighting
import hljs from 'highlight.js/lib/core'
import json from 'highlight.js/lib/languages/json'
import 'highlight.js/styles/github.css'

require('@rails/ujs').start()
//require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../../assets/images', true)
const imagePath = (name) => images(name, true)
require('stylesheets/application.css')

// add frontend css for custom login
require('stylesheets/frontend.css')
hljs.registerLanguage('json', json)

// code highlighting for json configs etc.
document.addEventListener('turbolinks:load', (event) => {
  document.querySelectorAll('pre code').forEach((block) => {
    hljs.highlightElement(block)
  })
})

// Add Swagger UI for frontend api docs
// If turbolinks are deactivated use the Event 'DOMContentLoaded' instead
document.addEventListener('turbolinks:load', (event) => {
  const element = document.getElementById('swagger_frontend_api_docs')
  if (element != null) {
    const spec = require('../../../docs/swagger_frontend.yaml')
    SwaggerUI({
      spec,
      dom_id: '#swagger_frontend_api_docs'
    })
  }
})

// Add Swagger UI for device api docs
// If turbolinks are deactivated use the Event 'DOMContentLoaded' instead
document.addEventListener('turbolinks:load', (event) => {
  const element = document.getElementById('swagger_device_api_docs')
  if (element != null) {
    const spec = require('../../../docs/swagger_central_module.yaml');
    SwaggerUI({
      spec,
      dom_id: '#swagger_device_api_docs'
    })
  }
})

// Support component names relative to this directory:
var componentRequireContext = require.context('components', true)
var ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)
