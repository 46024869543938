import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['dropdown', 'hidableSection']

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    this.hidableSectionTargets.forEach(hidableSection => {
      hidableSection.style.display = this.dropdownTarget.value === hidableSection.dataset.showWhenSelected ? "block" : "none"
    });
  }

  dropdownValueChanged() {
    this.updateVisibility()
  }
}

