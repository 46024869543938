import { Controller } from "stimulus"

const LOCAL_STORAGE_ITEM_NAME = 'selectedLocaleForLogin'

export default class extends Controller {
  static targets = [ 
    "email", "password", "rememberMe", "toggleButton", "loginButton",
    "forgotPassword", "localeInput"
  ]
  
  connect() {
    // try to get locale from localstorage
    const savedLocale = localStorage.getItem(LOCAL_STORAGE_ITEM_NAME);
    this.locale = savedLocale != null ? savedLocale : "en"

    // TODO: get these locales from i18n file
    this.i18n = {
      de: {
        email: "E-Mail-Adresse*",
        password: "Passwort*",
        login: "Anmelden",
        rememberMe: "Angemeldet bleiben",
        forgotPassword: "Passwort vergessen?"
      },
      en: {
        email: "E-Mail-Address*",
        password: "Password*",
        login: "Login",
        rememberMe: "Remember me",
        forgotPassword: "Forgot password?"
      }
    }
    this.updateLabels()
    this.setLocaleToHiddenInput(this.locale)
  }



  changeLocale(e) {
    e.preventDefault()

    // toggle between "de" and "en"
    if (this.locale === "de") {
      this.locale = "en"
    } else {
      this.locale = "de"
    }

    this.updateLabels()

    // set hidden input to save locale for user after successful login
    this.setLocaleToHiddenInput(this.locale)

    // save selected locale to localstorage
    localStorage.setItem(LOCAL_STORAGE_ITEM_NAME, this.locale);
  }

  updateLabels() {
    this.emailTarget.innerHTML = this.i18n[this.locale]["email"]
    this.passwordTarget.innerHTML = this.i18n[this.locale]["password"]
    this.rememberMeTarget.innerHTML = this.i18n[this.locale]["rememberMe"]
    this.loginButtonTarget.innerHTML = this.i18n[this.locale]["login"]
    this.forgotPasswordTarget.innerHTML = this.i18n[this.locale]["forgotPassword"]
    
    if (this.locale === "en") {
      this.toggleButtonTarget.innerHTML = '<span class="fw-bold">EN</span>/DE'
    } else {
      this.toggleButtonTarget.innerHTML = 'EN/<span class="fw-bold">DE</span>'
    }
  }

  setLocaleToHiddenInput(locale) {
    this.localeInputTarget.value = locale
  }
}
