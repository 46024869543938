import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['selectedItem', 'rs232Settings', 'tcpipSettings']

  connect() {
    this.changeVisibility()
  }


  changeVisibility() {
    if (this.hasSelectedItemTarget) {

      // set each setting division to invisible
      if (this.hasRs232SettingsTarget) this.rs232SettingsTarget.style.display = 'none'
      if (this.hasTcpipSettingsTarget) this.tcpipSettingsTarget.style.display = 'none'

      // set the setting division that belongs to selected value visible
      switch (this.selectedItemTarget.value) {
        case "FieldModules::ConnectivityRs232":
          if (this.hasRs232SettingsTarget) this.rs232SettingsTarget.style.display = 'block'
          break
        case "FieldModules::ConnectivityTcpip":
          if (this.hasTcpipSettingsTarget) this.tcpipSettingsTarget.style.display = 'block'
          break
        default:
          break
      }
    }
  }



}

